export enum MenuItemAction {
	SHOW = 'show',
	SHOW_ALTERNATIVE = 'show_alternative',
	SHOW_INVOICE = 'show_invoice',
	STATUS = 'status',
	EDIT = 'edit',
	MOVE = 'move',
	DELETE = 'delete',
	REFRESH = 'refresh',
	DOWNLOAD = 'download',
	DUPLICATE = 'duplicate',
	SENT = 'sent',
	CANCEL = 'cancel',
	CREATE = 'create',
	CREATE_ALTERNATIVE = 'create_alternative',
	ARCHIVE = 'archive',
}

export enum Status {
	ACTIVE = 'active',
	DEACTIVE = 'deactive',
	PENDING = 'pending',
	DELETED = 'deleted',
	WAITING = 'waiting',
	IN_PROCESS = 'in_process',
	KO = 'ko',
	ERROR = 'error',
	COMPLETED = 'completed',
	SCHEDULED = 'scheduled',
	SENT = 'sent',
	CANCELLED = 'cancelled',
	CREATED = 'created',
	RESERVED = 'reserved',
	EXPIRED = 'expired',
	PROVISIONING = 'provisioning',
	TERMINATED = 'terminated',
	SUSPENDED = 'suspended',
	TERMINATING = 'terminating',
	CANCELLING = 'cancelling',
	PROVISIONED = 'provisioned',
	ANNULLING = 'annulling',
	ANNULLED = 'annulled',
	PENDING_SIM = 'pending_sim',
}

export enum StatusIncidents {
	OPEN = 'open',
	CLOSED = 'closed',
	IN_PROCESS = 'in_process',
	PENDING_INFO = 'pending_info',
	PENDING_INFO_ONIVIA = 'pending_info_onivia',
	CLEARED = 'cleared',
}

export type TStatus = Status.ACTIVE | Status.DEACTIVE | Status.PENDING | Status.DELETED;

export enum OperationalState {
	PENDING_INSTALLATION_FI = 'PENDING_INSTALLATION_FI',
	PENDING_INSTALLATION = 'PENDING_INSTALLATION',
}

export enum Provider {
	ONIVIA = 'onivia',
}

export enum Footprint {
	DIRECT = 'direct', // Huella Onivia
	INDIRECT = 'indirect', // Finetwork
}
